<template>
  <v-theme-provider>
    <base-section
      id="info-pacific"
    >
      <v-container>
        <v-row>
          <v-col>
            <base-info-pacific />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <base-business-contact-pacific
            dense
            countury="Francis Ho"
            />
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionInfoPacific',
  }
</script>

<style lang="sass">
  #info-alt a
    text-decoration: none
</style>
